a {
  text-decoration: none;
}
.Alignright {
  display: flex;
  justify-content: flex-end;
}
.bg-green {
  background-color: #37bf36 !important;
  color: #fff;
}
.text-green {
  color: #37bf36;
}
.bodyText {
  font-size: 18px;
}
ul.navbar-nav {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
a.nav-link {
  padding: 10px 20px !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #37bf36;
  border-bottom: 2px solid;
}
.btnSignup {
  background-color: #37bf36;
  color: #fff;
}
.btnSignup:hover {
  background-color: #37bf36;
  color: #fff;
}
.btn {
  padding: 10px 20px;
}
.btnSignin:hover {
  background-color: #37bf36;
  color: #fff;
  border: 0 !important;
}
.bannerSection {
  background-image: url(./img/banner-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 200px;
}

.bannerHeadingTop {
  color: #37bf36;
  font-size: 24px;
  font-weight: 500;
}
.bannerHeading {
  color: #222d2e;
  font-size: 46px;
  font-weight: 800;
}

.bannertext {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}

.bannerIcontext {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;
}
.text-success-ico {
  color: #37bf36;
}

.heading_k {
  font-size: 46px;
  font-weight: 700;
  color: #fff;
}
.greenRow {
  border-radius: 30px;
  margin-top: -50px;
}
.heading_num {
  font-size: 76px;
  font-weight: 700;
  color: #fff;
}
.sectionFeatures {
  padding: 100px 0;
}
.feature-text {
  color: #37bf36;
  font-size: 24px;
  font-weight: 500;
}
.feature-heading {
  color: #222d2e;
  font-size: 46px;
  font-weight: 700;
}
.feature-card {
  padding: 30px;
  background: #f5f5f5;
  border-radius: 15px;
}
.card-body {
  margin-top: 20px;
}
.card-img-one {
  background: #ffffff3b;
  padding: 10px;
}
.card-img-two {
  background: #fff;
  padding: 10px;
}
.featureImage {
  width: 500px;
}
.headint-top {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
button.btn.btn-dark.text-white {
  margin-top: 20px;
}
.box1 {
  text-align: center;
}
.qr-form1 {
  border-radius: 40px 0 0 40px;
}
.qr-form2 {
  border-radius: 0 40px 40px 0;
}

.box2 h1 {
  text-align: center;
}
.box2 label {
  color: #626b76;
  font-size: 20px;
}
.box2 select {
  border-radius: 25px;
  background-color: red;
  margin: auto;
  margin-top: 10px;
  color: white;
  height: 9.5%;
}
.bg-dark {
  background-color: #212529 !important;
}
.form-select {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.accesForm {
  width: 75%;
  margin: auto;
}
button.btn.text-white.rounded-pill.bg-green {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .featureImage {
    width: 300px !important;
  }
  .qr-form1 {
    border-radius: 40px 40px 0px 0px;
  }
  .qr-form2 {
    border-radius: 0 0 40px 40px;
  }
  .accesForm {
    width: 100%;
    margin: auto;
  }

  button.bg-dark.py-3.px-5.rounded-pill.text-white.m-2 {
    margin-bottom: 50px !important;
    width: 96% !important;
    font-size: 30px !important;
  }
  select.form-select.rounded-pill.bg-white {
    width: 100% !important;
  }
  .complete.border.text-center.p-5.rounded-lg {
    width: 90% !important;
    padding: 20px !important;
  }
  .loginSocial {
    width: 100% !important;
  }
  .btn {
    padding: 10px 10px !important;
  }
  .map-btn-container {
    display: none !important;
  }
  .map-items-carosual .owl-nav {
    display: none !important;
  }
  .map-items-carosual {
    margin-bottom: 5% !important;
  }
  .map-items-carosual .row {
    display: block !important;
  }
  /* .map-items-carosual .owl-item {
        width: 320px !important;
    } */
  .row {
    display: block;
  }
  .col-6,
  .col-8,
  .col-4,
  .col {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .body-section {
    height: 100% !important;
  }
  .filter-container {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .nearby-parkings-container {
    position: relative !important;
    background: rgb(255, 255, 255);
    padding: 20px !important;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 100% !important;
    transform: translateY(0%) !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100% !important;
  }
  table {
    border-radius: 10px;
    width: 100%;
  }
  .nav-link:after {
    content: url(./img/next-btn.png);
    margin-top: 5px;
  }
  .form-group.form-check {
    display: block !important;
  }
  .TotalHours p {
    margin-top: 10px !important;
  }
  .slotCar {
    height: 50px !important;
    width: 50px !important;
  }
  .ScheduleParking {
    margin-top: 20px !important;
  }
  #profile-container:after {
    margin-top: -16% !important;
    margin-left: 8% !important;
  }
}

.owl-item {
  padding: 30px;
  background: #f5f5f5;
  border-radius: 15px;
}
.card {
  background: transparent;
  border: 0;
}
.card-body {
  text-align: left;
}

.caro-card-footer {
  display: flex;
  align-content: center;
  align-items: center;
}
.caro-card-footer p {
  margin: 0;
  margin-left: 10px;
}
.section.text-center.text-white {
  background-image: url(./img/bg.png);
}

p.text_ks {
  font-size: 24px;
  font-weight: 700;
}

.section.text-center.text-white {
  background-image: url(./img/bg.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
}
h2.text_ds {
  font-weight: 700;
  font-size: 46px !important;
}
button.bg-dark.py-3.px-5.rounded-pill.text-white.m-2 {
  margin-bottom: 50px !important;
  width: 30%;
  font-size: 30px !important;
}
select.form-select.rounded-pill.bg-white {
  width: 40%;
}
h1.footer-nav-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 22px !important;
}
.footer-nav a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
  margin-top: 22px !important;
}
.owl-carousel .owl-nav.disabled {
  display: block !important;
}

.owl-nav button {
  background: white !important;
  border: 1px solid #ddd !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #37bf36 !important;
  font-size: 26px;
}
.owl-carousel .owl-nav.disabled {
  display: flex !important;
  justify-content: space-between;
  width: 78%;
  margin: auto;
  margin-top: -12%;
  z-index: 124;
  position: absolute;
  left: 11%;
}
.header-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.navbar-jssd {
  padding: 10px;
}
#email,
#password,
#rpassword {
  padding: 10px 10px !important;
}

.content-contaier {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.language {
  position: relative;
  width: 100px;
  font-weight: bold;
}
.language-selected {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 30px;
}
.language-selected-icon {
  margin-left: 20px !important;
}

.language-selected.change-br:before {
  background-image: url(https://www.countryflags.io/br/flat/32.png);
}
.language-selected.change-en:before {
  background-image: url(https://www.countryflags.io/us/flat/32.png);
}
.language-selected.change-es:before {
  background-image: url(https://www.countryflags.io/es/flat/32.png);
}

.language ul {
  position: absolute;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding-top: 0;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 50%);
  display: none;
  padding: 10px;
}

.language ul li {
  list-style: none;
}

.language ul li a {
  display: block;
  color: #252525;
  padding: 5px 10px;
}

.language ul li:hover {
  background-color: rgb(243, 243, 243);
}

.language ul li a:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 10px;
}

a.br:before {
  background-image: url(https://www.countryflags.io/br/flat/32.png);
}

a.en:before {
  background-image: url(https://www.countryflags.io/us/flat/32.png);
}
a.es:before {
  background-image: url(https://www.countryflags.io/es/flat/32.png);
}

.language:hover ul {
  display: block;
}
#userMenuContaier {
  z-index: 999;
  position: absolute;
  background: #fff;
  width: 300px;
  margin-top: 10px;
  right: 0;
  margin-right: 14%;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 20px;
  padding: 30px;
}

.user-nav-link {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.link-text {
  display: flex;
  align-content: center;
  align-items: center;
}

.link-text p {
  margin: 0;
  font-size: 16px;
  margin-left: 10px;
}
.user-nav-link {
  height: 40px;
  border-color: #eee;
}

.search-button {
  position: absolute;
  outline: 0;
  border: 0;
  background: transparent;
  margin-top: 12px;
}
.filter-button {
  position: absolute;
  outline: 0;
  border: 0;
  background: transparent;
  margin-top: 12px;
  margin-left: -30px;
}
.search-field {
  padding-left: 40px;
  height: 50px;
  width: 100%;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border: 1px solid #adb5bd;
}
.body-section {
  height: 100%;
}
.map-section {
  background-image: url(./img/map.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 0;
  border-top: 1px solid #adb5bd;
}

.map-section {
  height: 100vh;
}

.filter-container {
  z-index: 999;
  position: absolute;
  background: #fff;
  padding: 30px;
  margin-top: 10px;
  right: 0;
  border-radius: 15px;
  width: 440px;
  margin-right: 14%;
}
.rating-container {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.rating-container img {
  width: 100%;
  margin: 5px;
}
.rating-btn {
  outline: 0;
  border: 0;
  padding: 0;
  background: transparent;
}
.filter-container h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.add-filter-button {
  background: transparent;
  border-radius: 30px;
  border: 1px solid #eeeeee;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #000 !important;
  display: flex;
  align-items: center;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: #37bf36 !important;
  border-color: #37bf36 !important;
  color: #fff !important;
}

.btn-check + .btn:hover {
  background: #37bf36 !important;
  border-color: #37bf36 !important;
  color: #fff !important;
}
.apply-filter-btn {
  background: #37bf36;
  height: 50px;
  width: 200px;
  border-radius: 30px;
  border: 0;
  color: #fff;
  margin-top: 30px;
}
.filterBTN {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-section p {
  text-align: center;
  font-size: 16px;
  margin: 0;
}
.footer-section {
  padding: 21px;
}

.checkbox-btn {
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}

.map-btn-container {
  position: fixed;
  bottom: 0;
  margin-bottom: 20%;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.map-btn-container.jhd {
  margin-bottom: 8% !important;
}
.map-items-carosual .owl-nav {
  display: flex;
  justify-content: space-around;
  position: absolute;
  margin-top: -16%;
  width: 100%;
}
.map-items-carosual {
  position: fixed;
  bottom: 0;
  margin-bottom: 3%;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.map-btn {
  background: none;
  border: none;
  padding: 0;
  width: 70px;
  height: 60px;
}

.humbs_down_section {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 180px;
}
.humbs_down_section h3 {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 20px;
}
.thumbsdown_button {
  background: green;
  border: 1px solid green;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  padding: 5px 15px 5px 15px;
}
.humbs_down_section img {
  padding-bottom: 60px;
  width: 100%;
}

/*-----------------------------*/

.thumbs_section {
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
}
.thumbs_section h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}
.thumbsup_button {
  background: green;
  border: 1px solid green;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  padding: 5px 15px 5px 15px;
}
.humbs_up_section img {
  padding-bottom: 60px;
  width: 100%;
}

.footer-section {
  background: #f5f5f5;
}

/*-----------------------------*/

.qr_section {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
}
.qr_section h3 {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 40px;
}
.qr_button {
  background: transparent;
  border: 1px solid green;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  color: green;
  padding: 5px 15px 5px 15px;
  height: 50px;
  width: 200px;
}
.qr_section img {
  padding-bottom: 60px;
  width: 100%;
}

.status_button {
  background: #37bf36;
  height: 50px;
  width: 200px;
  border-radius: 30px;
  border: 0;
  color: #fff;
  margin-top: 30px;
}

.schedual-button {
  background: #37bf36;
  height: 50px;
  width: 150px;
  border-radius: 30px;
  border: 0;
  color: #fff;
}

.schedual-address {
  display: flex;
  align-items: center;
}
.schedual-address p {
  margin-bottom: 0;
  margin-left: 10;
}

.parking_schedual_section {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
}
.col.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time-td ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
.time-td li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ddd;
  height: 50px;
}

.time-td {
  padding: 0;
  background: #f5f5f5;
  width: 110px;
}
table {
  border-radius: 10px;
}
table,
td,
tr,
thead,
tbody,
th {
  border-color: #dddddd !important;
}
.schedual-values td {
  border-color: #dddddd !important;
  border: 1px solid;
}
ul.table-head-values {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.table-head-values li {
  width: 110px;
  text-align: center;
}
.schedual-values {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.schedual-values td {
  padding: 0;
}
.park-status {
  margin: 0;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  margin: auto;
  border-radius: 10px;
  color: #fff;
  flex-direction: column;
}
.park-red {
  background: #bf3636;
}
.park-green {
  background: #37bf36;
}
.park-status p {
  border-radius: 50%;
  border: 2px solid;
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 14px;
}
.park-green:after {
  content: "";
  width: 17px;
  border: 1px solid;
  position: absolute;
  transform: rotate(45deg);
  margin-top: -16px;
}

.park-status span {
  font-size: 12px;
}
ul.table-head-values {
  border: 1px solid #ddd;
  height: 50px;
  border-bottom: 0;
  align-items: center;
}
.table-head-values li {
  width: 144px;
  text-align: center;
  border-right: 1px solid #ddd;
}
.time-td li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ddd;
  height: 50px;
  width: 144px;
}
.table-head-values li {
  width: 148px;
  text-align: center;
  border-right: 1px solid #ddd;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.time-td {
  padding: 0;
  background: #f5f5f5;
  width: 110px;
  border-right: 0;
}
.time-td li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ddd;
  height: 50px;
  width: 146px;
  border-right: 0;
  text-align: center;
}

ul.table-head-values {
  margin-top: 10px;
}
.schedual-values td {
  padding: 0;
  width: 164px;
}
.date-range-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
}
.date-range p {
  margin: 0;
}
.date-range-container {
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  border-bottom: 0;
  margin-top: 10px;
}
.location-r img {
  width: 30px !important;
  margin-right: 10px;
}
.location-r {
  display: flex !important;
  align-items: center;
}
.map-items-carosual .owl-item {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 15px;
  border: 1px solid #37bf36;
  width: 400px;
}
.save-btn {
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  margin-top: -35px;
}
.map-items-carosual h6 {
  font-size: 18px;
}
p.price-tag {
  color: #37bf36;
  font-size: 16px;
}
.location-r {
  margin-top: 10px;
}

.nearby-parkings-container {
  position: absolute;
  background: rgb(255, 255, 255);
  padding: 50px;
  border-radius: 10px 10px 0px 0px;
  bottom: 0;
  width: 80%;
  transform: translateY(23%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 578px;
}
.schedual-address img {
  margin-right: 10px;
}
.nearby-parkings-details-popup h4 {
  font-size: 20px;
}
.nearby-Btn {
  width: 105px;
  border: 0;
  border-radius: 30px;
  height: 40px;
  margin-top: 10px;
}
.btn-call {
  background-color: #37bf36;
  color: #fff;
}
.btn-direction {
  background-color: #222d2e;
  color: #fff;
}
.btn-share {
  background-color: transparent;
  border: 1px solid #37bf36;
  color: #37bf36;
}
.readmorebtn {
  color: #37bf36;
}

.ParkPlace td {
  padding-right: 20px;
}
.ParkPlace h3 {
  font-size: 20px;
}
.parking-rate-container {
  width: 100%;
}

.parking-rate-table {
  width: 100%;
}
.parking-rate-table tr::after {
  content: url(./img/right-arrow.png);
  margin-right: 20px;
}
.viewMoreBtn {
  font-size: 16px;
  color: #37bf36;
  background: none;
  border: 0;
  border-bottom: 1px solid #37bf36;
  padding: 0;
}

.ScheduleParking {
  font-size: 16px;
  color: #fff;
  background: #37bf36;
  width: 310px;
  height: 50px;
  border-radius: 30px;
  border: 0;
  border-bottom: 1px solid #37bf36;
  padding: 0;
}

.CancelBtn {
  font-size: 16px;
  color: #000;
  background: none;
  border: 0;
  padding: 0;
  margin-top: 20px;
}

.modal {
  background: #0000005e;
  backdrop-filter: blur(20px) brightness(110%);
}
.modal-header,
.modal-footer {
  border: none;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

.form-group.form-check {
  text-align: center;
}
#parking-steps h3 {
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

#parking-steps h2 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

#parking-steps .add-filter-button {
  padding: 10px 30px !important;
  margin: 5px;
}
.form-group.form-check {
  display: flex;
  justify-content: center;
}

.TotalHours p {
  padding: 10px 20px !important;
  background: #37bf36;
  margin: 0 30px;
  color: #fff;
  border-radius: 30px;
}
.pick-time {
  border: 0;
  font-size: 20px;
  margin-top: 10px;
}
.form-group.form-check {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #eeeeee;
  padding: 20px 10px;
  border-radius: 10px;
}

.parking-slot-table-container {
  border: 1px solid #eeeeee;
  padding: 20px 10px;
  border-radius: 10px;
}

.modal {
  --bs-modal-width: 550px !important;
}
.slotCar {
  background: url(./img/slot-car.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  height: 80px;
  width: 108px;
  background-position: center;
}
.slot-check {
  display: none;
}
.slotCar {
  color: transparent;
}

.slot-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.slot-check) + .btn:active {
  background: #37bf36 !important;
  border-color: #37bf36 !important;
  color: #fff !important;
}

.slot-check + .btn:hover {
  background: url(./img/slot-car.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  height: 80px;
  width: 108px;
  background-position: center;
  color: transparent !important;
  border-color: #37bf36 !important;
}
.slot-check:checked + .btn:hover {
  background: url(./img/slot-car.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  height: 80px;
  width: 108px;
  background-position: center;
  color: transparent !important;
  color: #fff !important;
  border-color: #37bf36 !important;
}

.modal-footer {
  justify-content: center;
}
.parking-process_btn {
  font-size: 16px;
  color: #fff;
  background: #37bf36;
  width: 310px;
  height: 50px;
  border-radius: 30px;
  border: 0;
  border-bottom: 1px solid #37bf36;
  padding: 0;
}
.CancelBtn:hover {
  background: transparent;
  color: #000;
}
.CancelBtn:active {
  background: transparent;
  color: #000;
}
.modal .CancelBtn {
  margin-top: 0;
  margin-bottom: 20px;
}
.slot-content {
  display: flex;
  align-items: center;
  align-content: center;
}
.price-slot p {
  margin: 0;
  font-size: 16px;
}
.p-sign {
  border-radius: 50%;
  border: 2px solid;
  width: 25px;
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 16px;
}

.slotCar.btn {
  padding: 15px 20px !important;
}
.slot-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentBtn {
  height: 105px;
  width: 105px;
  border-radius: 15px;
  outline: none;
}
.apple-payment {
  background: #37bf36;
  border-color: #37bf36;
}
.card-payment {
  background: #222d2e;
  border-color: #222d2e;
  color: #fff;
}
.wallet-payment {
  background: transparent;
  border-color: #37bf36;
  color: #37bf36;
}

.parking-spot {
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  width: 85%;
  margin: auto;
}
.parking-spot h4 {
  font-size: 20px;
}

.parking-spot p {
  font-size: 16px;
  margin-bottom: 0;
}

.tiket-details {
  text-align: left !important;
  width: 90%;
  margin: auto;
}
.tiket-value {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.tiket-btn {
  border: 0;
  border-radius: 30px;
  width: 105px;
  height: 40px;
}
.tiket-btns {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.call-btn {
  background: #37bf36;
  color: #fff;
}
.direction-btn {
  background: #222d2e;
  color: #fff;
}
.direction-btn {
  background: transparent;
  border: 1px solid #37bf36;
  color: #37bf36;
}
.extend-time {
  list-style: none;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 20px;
  overflow: auto;
  height: 282px;
}
.extend-time li {
  padding: 15px;
  border-top: 1px solid#eee;
  border-bottom: 1px solid#eee;
  color: #222d2e;
}
.nave-itema .nav-link a {
  color: #222d2e !important;
  font-size: 16px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #37bf36 !important;
}
.nave-item .nav-link.active svg {
  fill: #37bf36 !important;
}

li.nave-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nave-item:after {
  content: url(./img/next-btn.png);
  margin-right: 10px;
}

.parking-recipt-container img {
  width: 170px;
}
.btn-view-detail {
  background: #37bf36;
  padding: 12px 25px;
  border-radius: 50px;
  color: #37bf36;
  border: 1px solid #37bf36;
  margin-top: 20px;
}

.parking-recipt-container {
  padding: 15px;
  border: 1px solid #37bf36;
  border-radius: 10px;
  margin-top: 10px;
}
.favorite-parking-spots {
  border: 1px solid #37bf36;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
}

img.fav-list-image {
  width: 100%;
}
.fav-btn {
  background: none;
  border: none;
}
.fav-btn-container {
  display: flex;
  justify-content: end;
}

.paymentContainer .paymentBtn {
  height: 255px !important;
  width: 100% !important;
  border-radius: 15px;
  outline: none;
}
#imageUpload {
  display: none;
}

#profileImage {
  cursor: pointer;
}

#profile-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

#profile-container img {
  width: 120px;
  height: 120px;
}
#profile-container:after {
  content: url(./img/profile-upload.png);
  position: absolute;
  display: flex;
  margin-top: -4%;
  margin-left: 3%;
}
.profileContainer {
  margin-top: 50px;
}
.profile_pic_name {
  display: flex;
  align-items: center;
}
.profile_pic_name h4 {
  margin-left: 20px;
  font-size: 24px;
  font-weight: 600;
}

.account-header {
  align-items: center;
}
.account-header-btn {
  text-align: right;
}
.account-header-btn button {
  background: #37bf36;
  border: 0;
  border-radius: 30px;
  color: #fff;
  padding: 15px 30px;
}
.input-group-prepend {
  position: absolute;
  z-index: 1;
  background: transparent;
}
.input-group-text {
  border: none !important;
  background-color: transparent;
  height: 50px;
}
.input-group-text {
  color: #37bf36;
}
input#inlineFormInputGroup {
  padding-left: 35px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 50px;
  margin-bottom: 10px;
}
